import { fromScreen, toScreen } from '@doltech/core/lib/responsive/responsive.util';
import * as React from 'react';
import styled from 'styled-components';

const SeparationSectionLayoutWrapper = styled.div`
  max-width: 1080px;
  padding: 32px 0;

  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 60px;

  ${fromScreen(768)} {
    gap: 120px;
    padding: 120px 0;
  }
  ${toScreen(1127)} {
    .swiper-main {
      gap: 16px;
    }
  }
`;

export const SeparationSectionLayout = ({ children }) => {
  return <SeparationSectionLayoutWrapper>{children}</SeparationSectionLayoutWrapper>;
};
