import * as React from 'react';

function LocalIconInterfaceEssentialNews(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 33" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M25.333 28.014v0a2.666 2.666 0 002.666-2.667v-8c0-.736-.597-1.333-1.333-1.333h-4v9.333a2.666 2.666 0 002.667 2.667z"
        stroke="currentColor"
        strokeWidth={2.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.334 10.68h8M9.334 15.838h8M9.334 21.428h4M22.667 16.014V6.681c0-.736-.598-1.333-1.334-1.333h-16C4.597 5.348 4 5.945 4 6.68v18.667a2.666 2.666 0 002.667 2.666h18.666"
        stroke="currentColor"
        strokeWidth={2.667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const IconInterfaceEssentialNews = React.memo(LocalIconInterfaceEssentialNews);
