import * as React from 'react';
import styled from 'styled-components';

import { IconSystemV2CourseBookCourse } from '@doltech/icons/SystemV2/IconSystemV2CourseBookCourse';
import { IconSystemV2CourseTestDictation } from '@doltech/icons/SystemV2/IconSystemV2CourseTestDictation';
import { IconSystemV2CourseTestSample } from '@doltech/icons/SystemV2/IconSystemV2CourseTestSample';
import { IconInterfaceEssentialNews } from '@doltech/icons-from-dev/IconInterfaceEssentialNews';
import { IconSystemV2CourseTestExercise } from '@doltech/icons/SystemV2/IconSystemV2CourseTestExercise';
import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { TitleHighlightHome } from '../../landing/ui/TitleHighlightHome/TitleHighlightHome';
import { CardHighlight } from '../../landing/ui/CardHighlight/CardHighlight';

const HIGHLIGHT_CONTENT = [
  {
    icon: <IconSystemV2CourseBookCourse />,
    color: 'purple',
    title: 'IELTS Online Test',
    description:
      'Đề thi IELTS Online Test với trải nghiệm thi thật và kho đề khủng kèm giải thích chi tiết.',
  },
  {
    icon: <IconSystemV2CourseTestExercise />,
    color: 'blue',
    title: 'Luyện tập Listening & Reading',
    description:
      'Kho đề luyện tập IELTS Listening & Reading với phân loại theo dạng câu hỏi và chủ đề.',
  },
  {
    icon: <IconSystemV2CourseTestDictation />,
    color: 'yellow',
    title: 'Nghe chép chính tả',
    description: 'Phần mềm luyện nghe chép chính tả theo từng câu với kho đề nghe IELTS và TOEIC.',
  },
  {
    icon: <IconSystemV2CourseTestSample />,
    color: 'green',
    title: 'Bài mẫu Writing & Speaking',
    description:
      'Tổng hợp bài mẫu IELTS Writing & Speaking band 8.0+ với dàn ý, bài mẫu, từ vựng và bài tập.',
  },
  {
    icon: <IconInterfaceEssentialNews />,
    color: 'primary',
    title: 'Tin tức Tiếng Anh',
    description:
      'Cập nhật liên tục mỗi ngày các tin tức Tiếng Anh mới nhất, đa dạng chủ đề và trình độ.',
  },
];

const Main = styled.div`
  display: grid;
  row-gap: 24px;
  ${fromScreen(768)} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 32px;
  }
  ${fromScreen(1128)} {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 48px;
  }
`;

export const SectionHighlightHome = () => {
  return (
    <Main className="section-highlight-home">
      <TitleHighlightHome />
      {HIGHLIGHT_CONTENT.map((card, idx) => (
        <CardHighlight {...card} key={idx} />
      ))}
    </Main>
  );
};
