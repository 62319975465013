import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1573 1.84309C19.0328 0.717581 17.2106 0.72036 16.0878 1.84309L7.61254 10.3235C7.21986 10.7162 7 11.2477 7 11.8019V14C7 14.5523 7.44772 15 8 15H10.1982C10.7547 15 11.2844 14.7775 11.6747 14.3894L11.6763 14.3877L20.1571 5.91274C21.2805 4.78932 21.2804 2.96673 20.1573 1.84309ZM18.7426 3.2568C18.4008 2.91452 17.8452 2.9141 17.502 3.25733L16.0872 4.67299L17.3273 5.91309L18.7431 4.49827C19.0856 4.15573 19.0851 3.59934 18.7426 3.2568ZM15.9126 7.32683L14.6734 6.08763L9.02696 11.7375C9.00946 11.755 9 11.778 9 11.8019V13H10.1982C10.2197 13 10.244 12.9913 10.2639 12.9718L15.9126 7.32683Z"
        fill="currentColor"
      />
      <path
        d="M15 15C14.4477 15 14 14.5523 14 14C14 13.4477 14.4477 13 15 13H18C18.5523 13 19 13.4477 19 14C19 14.5523 18.5523 15 18 15H15Z"
        fill="currentColor"
      />
      <path
        d="M6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17H6Z"
        fill="currentColor"
      />
      <path
        d="M4.22222 12C3.44502 12 3 12.5438 3 13V20C3 20.4562 3.44502 21 4.22222 21H19.7778C20.555 21 21 20.4562 21 20V13C21 12.5438 20.555 12 19.7778 12C19.2255 12 18.7778 11.5523 18.7778 11C18.7778 10.4477 19.2255 10 19.7778 10C21.4561 10 23 11.2462 23 13V20C23 21.7538 21.4561 23 19.7778 23H4.22222C2.54387 23 1 21.7538 1 20V13C1 11.2462 2.54387 10 4.22222 10C4.77451 10 5.22222 10.4477 5.22222 11C5.22222 11.5523 4.77451 12 4.22222 12Z"
        fill="currentColor"
      />
    </svg>
  );
});
export const IconSystemV2CourseTestDictation = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
