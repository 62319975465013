import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';

import { useFromScreens } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';
import { s3ToCDN } from '@doltech/utils/lib/url';
import * as React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  .highlight {
    color: ${colorsV2.primary100};
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  width: 344px;

  .text {
    max-width: 250px;

    position: relative;
    z-index: 1;
  }
  .question-img {
    position: absolute;
    right: 51px;
    top: 26px;
  }
`;

export const TitleHighlightHome = () => {
  const [, from768] = useFromScreens([0, 768]);

  const renderContent = React.useCallback(() => {
    if (from768) {
      return (
        <ContentWrapper>
          <ResponsiveTypography.Paragraph className="text" color="black100" variant="bold/32-40">
            Tự học IELTS của <span className="highlight">DOL</span> có gì
          </ResponsiveTypography.Paragraph>
          <div className="question-img">
            <img
              src={s3ToCDN(
                'https://dol-english-resource-dev.s3.ap-southeast-1.amazonaws.com/PUBLIC/MEDIA/Mark_dbd34eead1.svg'
              )}
              alt="question"
            />
          </div>
        </ContentWrapper>
      );
    }

    return (
      <ResponsiveTypography.Paragraph color="black100" variant="bold/24-32">
        Tự học IELTS của <span className="highlight">DOL</span> có gì ?
      </ResponsiveTypography.Paragraph>
    );
  }, [from768]);

  return <Main className="title-highlight-home">{renderContent()}</Main>;
};
