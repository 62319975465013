import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M4.332 19.668C4.097 19.433 4 19.175 4 19V5c0-.175.097-.433.332-.668C4.567 4.097 4.825 4 5 4h12c.175 0 .433.097.668.332.235.235.332.493.332.668v3.5a1 1 0 102 0V5c0-.825-.402-1.567-.918-2.082C18.567 2.403 17.825 2 17 2H5c-.825 0-1.567.403-2.082.918C2.403 3.433 2 4.175 2 5v14c0 .825.403 1.567.918 2.082C3.433 21.597 4.175 22 5 22h3a1 1 0 100-2H5c-.175 0-.433-.098-.668-.332z" clipRule="evenodd" /><path fill="currentColor" fillRule="evenodd" d="M6 11.5a1 1 0 011-1h1.8a1 1 0 110 2H7a1 1 0 01-1-1zm0-4a1 1 0 011-1h8a1 1 0 110 2H7a1 1 0 01-1-1zm9.5 5.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm3.889-.39a5.5 5.5 0 10-7.778 7.78 5.5 5.5 0 007.778-7.78z" clipRule="evenodd" /><path fill="currentColor" fillRule="evenodd" d="M18.293 19.293a1 1 0 011.414 0l2 2a1 1 0 01-1.414 1.414l-2-2a1 1 0 010-1.414z" clipRule="evenodd" /></svg>;
});
export const IconSystemV2CourseTestSample = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));