import withHydrationOnDemand from '@doltech/core/lib/hooks/withDolHydrationOnDemand';
import { GridContentV2 } from '@doltech/ui/lib/shared/components/PageSectionStructure/Content/GridContentV2';
import { SwiperContent } from '@doltech/ui/lib/shared/components/PageSectionStructure/Content/SwiperContent';
import { PageSectionStructureV2 } from '@doltech/ui/lib/shared/components/PageSectionStructure/PageSectionStructureV2';
import { PageTypeEnum } from '@doltech/utils/lib/constants';
import * as React from 'react';
import { PracticeTestCardForHomePage } from '../practice-test-landing/components/PracticeTestCardForHomePage';

import { SeparationSectionLayout } from '@doltech/ui/lib/shared/components/PageSectionStructure/Content/SectionLayout';
import { LandingSampleCard } from '../practice-skills/landing/components/LandingSampleCard';
import { HomeNewsCard } from './card/HomeNewsCard';
import { SectionHighlightHome } from './components/SectionHighlightHome';
import { HomeDictation } from './dictation/HomeDictation';
import { IEOnlineTestComponent } from './ie-online-test/IEOnlineTestComponent';
import { LandingPromotionLoader } from '@doltech/ui/lib/figma/Layout/Landing/LandingPromotionLoader';

export const HomePageComponentContent = withHydrationOnDemand({
  on: ['scroll', () => document.getElementById('app-root')],
})(
  ({
    staticPageLink,
    dictationData,
    dictationPageSection,
    homeNewsData,
    readingData,
    listeningData,
    onlineTestPageSection,
    onlineTestData,
    writingSampleData,
    speakingSampleData,
    listeningPracticeTestPageSection,
    readingPracticeTestPageSection,
  }) => {
    return (
      <SeparationSectionLayout>
        <SectionHighlightHome />
        <LandingPromotionLoader short />

        {homeNewsData?.content && (
          <PageSectionStructureV2
            seeMoreUrl={staticPageLink[PageTypeEnum.LANDING_DAILY_LEARNING_NEWS]?.url}
            oldStyling
            shouldHideSeeMoreText
            title="Tin Tiếng Anh mới nhất"
            content={
              <GridContentV2
                data={homeNewsData.content}
                renderCard={(cardData, index) => <HomeNewsCard data={cardData} order={index} />}
                gridColumn={[
                  {
                    breakpoint: 768,
                    numOfColumn: 1,
                  },
                  {
                    breakpoint: 1127,
                    numOfColumn: 2,
                  },
                  {
                    breakpoint: 1128,
                    numOfColumn: 3,
                  },
                ]}
              />
            }
          />
        )}

        {Boolean(onlineTestData?.length) && (
          <IEOnlineTestComponent
            data={onlineTestData}
            className="page-section"
            pageSection={onlineTestPageSection}
            imgPriority
          />
        )}
        <PageSectionStructureV2
          oldStyling
          title="IELTS Listening Practice"
          pageSection={listeningPracticeTestPageSection}
          seeMoreUrl={staticPageLink[PageTypeEnum.LANDING_LISTENING_PRACTICE_TEST]?.url}
          content={
            <SwiperContent
              data={listeningData.content}
              renderCard={(cardData) => <PracticeTestCardForHomePage data={cardData} />}
              breakpoints={[0, 1024, 1025]}
              slideWidths={[232, '100%', '100%']}
              gridColumns={['max-content', '1fr', '1fr']}
            />
          }
        />
        <PageSectionStructureV2
          oldStyling
          title="IELTS Reading Practice"
          pageSection={readingPracticeTestPageSection}
          seeMoreUrl={staticPageLink[PageTypeEnum.LANDING_READING_PRACTICE_TEST]?.url}
          content={
            <SwiperContent
              data={readingData.content}
              renderCard={(cardData) => <PracticeTestCardForHomePage data={cardData} />}
              breakpoints={[0, 1024, 1025]}
              slideWidths={[232, '100%', '100%']}
              gridColumns={['max-content', '1fr', '1fr']}
            />
          }
        />
        {Boolean(dictationData?.content?.length) && (
          <HomeDictation
            data={dictationData}
            seeMoreUrl={staticPageLink[PageTypeEnum.LANDING_DICTATION]?.url}
            pageSection={dictationPageSection}
          />
        )}
        <PageSectionStructureV2
          oldStyling
          title="IELTS Writing Sample"
          seeMoreUrl={staticPageLink[PageTypeEnum.LANDING_WRITING_SAMPLE]?.url}
          content={
            <SwiperContent
              data={writingSampleData}
              renderCard={(cardData) => <LandingSampleCard data={cardData} />}
              breakpoints={[0, 1024, 1025]}
              slideWidths={[232, '100%', '100%']}
              gridColumns={['max-content', '1fr', '1fr']}
            />
          }
        />
        <PageSectionStructureV2
          oldStyling
          title="IELTS Speaking Sample"
          seeMoreUrl={staticPageLink[PageTypeEnum.LANDING_SPEAKING_SAMPLE]?.url}
          content={
            <SwiperContent
              data={speakingSampleData}
              renderCard={(cardData) => <LandingSampleCard data={cardData} />}
              breakpoints={[0, 1024, 1025]}
              slideWidths={[232, '100%', '100%']}
              gridColumns={['max-content', '1fr', '1fr']}
            />
          }
        />
        <LandingPromotionLoader />
      </SeparationSectionLayout>
    );
  }
);
