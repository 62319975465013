import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import cl from 'classnames';

import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';

import * as React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  gap: 4px;

  ${fromScreen(768)} {
    gap: 12px;
  }
`;

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  &.primary {
    color: ${colorsV2.primary100};
    background-color: ${colorsV2.primary10};
  }
  &.purple {
    color: ${colorsV2.purple100};
    background-color: ${colorsV2.purple10};
  }
  &.green {
    color: ${colorsV2.green100};
    background-color: ${colorsV2.green10};
  }
  &.blue {
    color: ${colorsV2.blue100};
    background-color: ${colorsV2.blue10};
  }

  &.yellow {
    color: ${colorsV2.yellow100};
    background-color: ${colorsV2.yellow10};
  }

  svg {
    font-size: 20px;
  }

  ${fromScreen(768)} {
    width: 44px;
    height: 44px;
    svg {
      font-size: 24px;
    }
  }
`;

interface CardHighlightProps {
  icon: React.ReactNode;
  title: string;
  color: string;
  description: string;
}

export const CardHighlight = (props: CardHighlightProps) => {
  const { icon, title, description, color } = props;

  return (
    <Main>
      <IconWrapper className={cl('card-highlight', color)}>{icon}</IconWrapper>
      <ResponsiveTypography.Paragraph
        breakpoints={[0, 768]}
        variant={['semi-bold/16-24', 'semi-bold/20-28']}
        color="black100"
      >
        {title}
      </ResponsiveTypography.Paragraph>
      <ResponsiveTypography.Paragraph variant="regular/14-20" color="black80">
        {description}
      </ResponsiveTypography.Paragraph>
    </Main>
  );
};
