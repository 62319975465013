import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 3c0-.414.586-1 1-1h7c1.227 0 2.316.59 3 1.5A3.745 3.745 0 0115 2h7c.414 0 1 .586 1 1v15c0 .414-.586 1-1 1h-6.735c-.6 0-1.183.055-1.605.48-.324.327-.66 1.518-1.66 1.52-1 .002-1.437-1.257-1.68-1.5-.422-.422-.817-.5-1.414-.5H2c-.414 0-1-.586-1-1V3zm10 3.08C11 4.837 10.243 4 9 4H3v13h5.906c.895 0 1.418.12 2.094.697V6.08zm2 11.617V6.08C13 4.837 13.757 4 15 4h6v13h-5.735c-.892 0-1.59.125-2.265.697zM14.5 7.5c0-.592.5-1 1.05-1h2.943c.507 0 1.007.418 1.007 1 0 .582-.5 1-1.007 1H15.55c-.551 0-1.051-.408-1.051-1zm0 3.5c0-.5.5-1 1.05-1h1.95c.5 0 1.007.445 1.007 1 0 .555-.507 1-1.007 1h-1.95c-.55 0-1.05-.5-1.05-1z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M4.45 7.5c0-.592.5-1 1.05-1h2.942c.507 0 1.007.418 1.007 1 0 .582-.5 1-1.007 1H5.5c-.55 0-1.05-.408-1.05-1z"
      />
    </svg>
  );
});
export const IconSystemV2CourseBookCourse = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
